import { type FunctionComponent } from "react";
import { Icon } from "@iconify/react";
import Select from "react-select";
import { useValidation } from "./useFormHook";
import { ValidationRulesInterface } from "../validation";
import SelectStyle from "./SelectStyle";

interface OptionType {
  value: string;
  label: string;
}

interface SelectProps {
  id?: string;
  name: string;
  placeholder?: string;
  state: [any, (value: any) => void];
  validate?: ValidationRulesInterface;
  disabled?: boolean;
  children?: string;
  multiple?: boolean;
  options: OptionType[];
  isClearable?: boolean;
  className?: string;
  info?: string;
  noOptionsMessage?: (obj: any) => string;
  onChange?: (value: string, errors?: any) => void;
}

const SelectMenu: FunctionComponent<SelectProps> = ({
  options = [],
  state,
  name,
  validate,
  placeholder = "Select an option",
  id,
  disabled = false,
  multiple = false,
  children,
  isClearable = true,
  className = "",
  info,
  noOptionsMessage = () => "No options",
  onChange,
}) => {
  const { value, validateValue, error, errors, rules } = useValidation({
    state,
    name,
    validate,
  });

  const customStyles = SelectStyle(!!error);

  return (
    <div data-testid={id || name} className={className}>
      {children && (
        <label
          htmlFor={id || name}
          className="mb-2 block text-sm font-medium leading-6 text-slate-600"
        >
          <span className="flex">
            {children}
            {info && (
              <span aria-label={info} data-balloon-pos="up">
                <Icon icon="ph:info-bold" className="ml-1 text-gray-400" />
              </span>
            )}
          </span>
        </label>
      )}

      <span id={`${id || name}-select`} data-testid={`${id || name}-select`}>
        <Select
          {...rules}
          onChange={(selected: any) => {
            if (multiple) {
              // @ts-ignore
              validateValue(selected?.map((i) => i.value) ?? []);
            } else {
              validateValue(selected?.value ?? "");
            }

            if (onChange) {
              onChange(selected?.value, errors);
            }
          }}
          inputId={id || name}
          name={name}
          value={
            multiple
              ? options?.filter((i) => value?.includes(i.value))
              : options?.filter((i) => i.value === value?.toString())
          }
          options={options}
          noOptionsMessage={noOptionsMessage}
          placeholder={placeholder}
          styles={customStyles}
          isDisabled={disabled}
          isMulti={multiple}
          isSearchable
          isClearable={isClearable}
        />
      </span>

      {!!error && (
        <div
          id={`${id || name}-error`}
          className="ml-1 mt-1 text-[11px] font-medium tracking-wide text-red-600"
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default SelectMenu;

import { type FunctionComponent } from "react";
import CameraButton from "./Buttons/CameraButton";
import MicrophoneButton from "./Buttons/MicrophoneButton";
import ScreenShareButton from "./Buttons/ScreenShareButton";
import SettingsButton from "./Buttons/SettingsButton";
import LeaveCallButton from "./Buttons/LeaveCallButton";
const screenShareSupported = !!navigator?.mediaDevices?.getDisplayMedia;

interface MediaControlsProps {
  closeAllConnections?: () => Promise<boolean>;
  hasLeaveCallButton?: boolean;
  hasScreenShareButton?: boolean;
}

const MediaControls: FunctionComponent<MediaControlsProps> = ({
  closeAllConnections,
  hasLeaveCallButton = false,
  hasScreenShareButton = false,
}) => {
  return (
    <div className="flex gap-x-6">
      <CameraButton />
      <MicrophoneButton />
      {hasScreenShareButton && screenShareSupported && <ScreenShareButton />}
      <SettingsButton />
      {hasLeaveCallButton && closeAllConnections && (
        <LeaveCallButton closeAllConnections={closeAllConnections} />
      )}
    </div>
  );
};

export default MediaControls;

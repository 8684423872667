import { type FunctionComponent } from "react";
import Video from "../Video/Video";
import VideoDisplay from "../Video/VideoDisplay";
import useMeetingStore from "../store/MeetingStore";

const ScreenShareConferenceGrid: FunctionComponent = () => {
  const { me, screenSharingUser, gridUsers } = useMeetingStore();

  return (
    <div className="relative flex h-full w-full flex-col">
      {!!gridUsers.length && (
        <div className="horizontal-video-grid relative flex h-40 w-full justify-center px-6">
          {gridUsers.map((user: any) => (
            <VideoDisplay key={user.id} participant={user} />
          ))}
        </div>
      )}
      <div className="flex flex-1 items-center justify-center">
        <div className="w-full max-w-7xl">
          <div className="screen-share-display relative z-10 aspect-video overflow-hidden rounded-lg border-4 border-black bg-black shadow-lg">
            <Video
              firstName={screenSharingUser?.firstName}
              lastName={screenSharingUser?.lastName}
              image={screenSharingUser?.image}
              stream={screenSharingUser?.stream}
              videoEnabled={screenSharingUser?.videoEnabled}
              audioEnabled={screenSharingUser?.audioEnabled}
              muted={me?.id === screenSharingUser?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenShareConferenceGrid;

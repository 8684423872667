import { FunctionComponent, useEffect } from "react";
import useMeetingStore from "./store/MeetingStore";
import useSocketStore from "./store/SocketStore";
import useStreamStore from "./store/StreamStore";
import useSoundDetection from "./hooks/useSoundDetection";

const SoundTracking: FunctionComponent = () => {
  const { startSoundDetection, stopSoundDetection } = useSoundDetection();
  const { socket } = useSocketStore();
  const { roomId } = useMeetingStore();
  const { setIsTalking, audioEnabled, isTalking } = useStreamStore();

  useEffect(() => {
    if (!audioEnabled) {
      setIsTalking(false);
      return stopSoundDetection();
    }

    startSoundDetection();

    return () => {
      stopSoundDetection();
    };
  }, [audioEnabled]);

  useEffect(() => {
    socket?.emit("meeting.user.isTalking", { roomId, isTalking });
  }, [isTalking]);

  return null;
};

export default SoundTracking;

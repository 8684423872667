import { FunctionComponent, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { classNames } from "utils";
import LoadingSpinner from "../../../LoadingSpinner";
import useMediaToggle from "../../hooks/useMediaToggle";
import useUserMediaStore from "../../store/UserMediaStore";
import useStreamStore from "../../store/StreamStore";

const CameraButton: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [videoOn, setVideoOn] = useState(false);
  const [videoDenied, setVideoDenied] = useState(false);
  const { toggleVideo } = useMediaToggle();
  const { permissions } = useUserMediaStore();
  const { videoEnabled, hasVideoTrack } = useStreamStore();

  const onToggleVideoClicked = () => {
    if (loading) return;
    setLoading(true);
    toggleVideo().then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setVideoDenied(permissions.camera === "denied" ? true : false);
  }, [permissions]);

  useEffect(() => {
    setVideoOn(videoEnabled && hasVideoTrack);
  }, [videoEnabled, hasVideoTrack]);

  return (
    <div
      className="flex items-center"
      aria-label={videoOn ? "Video on" : "Video off"}
      data-balloon-pos="up"
    >
      <button
        type="button"
        className={classNames(
          videoDenied ? "bg-red-600" : "bg-white/20",
          "relative flex h-10 w-10 items-center justify-center rounded-full",
        )}
        onClick={() => onToggleVideoClicked()}
      >
        {videoDenied && (
          <Icon
            data-testid="video-denied-icon"
            icon="ri:information-fill"
            className="absolute right-0 top-0 -mr-1 -mt-1 text-xl text-orange-400"
          />
        )}
        {loading && (
          <div className="h-7 w-6">
            <LoadingSpinner size={6} />
          </div>
        )}
        {!loading && (
          <Icon
            data-testid={videoOn ? "video-icon" : "video-icon-muted"}
            icon={
              videoOn ? "fluent:video-32-filled" : "fluent:video-off-32-filled"
            }
            className="las text-2xl text-white"
          />
        )}
      </button>
    </div>
  );
};

export default CameraButton;

import { FunctionComponent, useEffect } from "react";
import Logo from "../Logo";
import useWebRTC from "./hooks/useWebRTC";
import useConference from "./hooks/useConference";
import MediaControls from "./MediaControls";
import ConferenceGrid from "./ConferenceGrid";
import Sidebar from "./Sidebar";
import SoundTracking from "./SoundTracking";
import useSocketStore from "./store/SocketStore";
import useMeetingStore from "./store/MeetingStore";

// Fix body to prevent root page from scrolling
const fixedClasses = [
  "bg-gray-900",
  "fixed",
  "w-full",
  "h-full",
  "top-0",
  "left-0",
  "overflow-hidden",
];

const Conference: FunctionComponent = () => {
  useConference();
  const { callParticipant, closeConnection, closeAllConnections } = useWebRTC();
  const { socket } = useSocketStore();
  const { setParticipant, removeParticipant, roomId } = useMeetingStore();

  useEffect(() => {
    const root = document.body.querySelector("#root");
    if (root) {
      root.classList.add(...fixedClasses);
    }
    return () => {
      const root = document.body.querySelector("#root");

      if (root) {
        root.classList.remove(...fixedClasses);
      }
    };
  }, []);

  useEffect(() => {
    if (!socket?.connected) return;

    const handleUserJoin = (participant: any) => {
      setParticipant(participant);
      callParticipant(participant.id);
    };

    const handleUserLeave = (participant: any) => {
      closeConnection(participant.id);
      removeParticipant(participant.id);
    };

    const handleUserStatus = ({
      id,
      videoEnabled,
      audioEnabled,
      hasScreenShare = false,
    }: any) => {
      setParticipant({ id, videoEnabled, audioEnabled, hasScreenShare });
    };

    const handleUserIsTalking = ({ id, isTalking }: any) => {
      setParticipant({ id, isTalking });
    };

    socket?.on("meeting.user.join", handleUserJoin);
    socket?.on("meeting.user.leave", handleUserLeave);
    socket?.on("meeting.user.status", handleUserStatus);
    socket?.on("meeting.user.isTalking", handleUserIsTalking);

    return () => {
      socket?.off("meeting.user.join", handleUserJoin);
      socket?.off("meeting.user.leave", handleUserLeave);
      socket?.off("meeting.user.status", handleUserStatus);
      socket?.off("meeting.user.isTalking", handleUserIsTalking);
    };
  }, [socket, roomId]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="relative flex h-full w-full text-white">
        <div className="flex flex-1 flex-col">
          <header className="flex h-20 w-full items-center px-4">
            <Logo white />
          </header>
          <div className="mb-4 flex flex-1 items-center justify-center">
            <ConferenceGrid />
          </div>
          <div className="mb-6 mt-auto flex justify-center">
            <MediaControls
              closeAllConnections={closeAllConnections}
              hasScreenShareButton
              hasLeaveCallButton
            />
          </div>
        </div>
        <Sidebar />
      </div>
      <SoundTracking />
    </div>
  );
};

export default Conference;

import { type FunctionComponent } from "react";
import { classNames } from "utils";

const Logo: FunctionComponent<{
  white?: boolean;
  minimized?: boolean;
  className?: string;
}> = ({ white = false, minimized = false, className = "" }) => {
  return (
    <div className={`flex items-center ${className}`} data-testid="logo">
      {!minimized && (
        <>
          <div
            className={classNames(
              white ? "text-white" : "text-blue-900",
              "text-2xl font-bold tracking-tighter",
            )}
          >
            Law
            <span className={classNames(white ? "text-white" : "text-sky-700")}>
              bind
            </span>
          </div>
          <span className="ml-2 rounded bg-yellow-200 px-1 text-[11px] font-medium text-yellow-700">
            Beta
          </span>
        </>
      )}
      {minimized && (
        <div
          className={classNames(
            white ? "text-white" : "text-blue-900",
            "text-2xl font-bold tracking-tighter",
          )}
        >
          LB
        </div>
      )}
    </div>
  );
};

export default Logo;

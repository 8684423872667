import { FunctionComponent, Children, isValidElement } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

interface DropdownProps {
  children: any;
  button: any;
  className?: string;
}

interface DropdownButtonProps {
  onClick?: () => void;
  children: any;
  lined?: boolean;
}

// Helper function to traverse children deeply and extract body and footer content
const extractContent = (children: any) => {
  const buttonContent: any = [];

  const traverseChildren = (nodes: any) => {
    Children.forEach(nodes, (child: any) => {
      if (isValidElement(child)) {
        // @ts-ignore
        if (child.type.componentType === "DropdownButton") {
          buttonContent.push(child);
          // @ts-ignore
        } else if (child?.props?.children) {
          // @ts-ignore
          traverseChildren(child.props.children);
        }
      }
    });
  };

  traverseChildren(children);

  return { buttonContent };
};

const Dropdown: FunctionComponent<DropdownProps> & {
  Button: FunctionComponent<DropdownButtonProps>;
} = ({ children, className, button }) => {
  const { buttonContent } = extractContent(children);

  return (
    <Menu as="div" className={className}>
      <MenuButton>{button}</MenuButton>
      <MenuItems
        transition
        anchor="bottom end"
        className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="group">
          {Children.toArray(buttonContent)?.map((item: any, index: number) => {
            return (
              <div key={index}>
                {item.props.lined && <hr className="my-1 bg-gray-100" />}
                <MenuItem
                  as="button"
                  className="group flex w-full items-center px-4 py-2 text-xs font-medium text-gray-500 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                  onClick={item.props.onClick}
                  type="button"
                >
                  {item}
                </MenuItem>
              </div>
            );
          })}
        </div>
      </MenuItems>
    </Menu>
  );
};

Dropdown.Button = ({ children }: DropdownButtonProps) => <>{children}</>;
// @ts-ignore
Dropdown.Button.componentType = "DropdownButton";

export default Dropdown;

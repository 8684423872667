import { type FunctionComponent } from "react";
import { Link } from "react-router";
import { Icon } from "@iconify/react";
import { SEO } from "ui";

const head = [{ title: "404 - Not Found | Lawbind" }];

export const getServerSideProps = async () => {
  return {
    head,
  };
};

const NotFound: FunctionComponent = () => {
  return (
    <>
      <SEO head={head} />
      <div className="mb-10 mt-10 lg:mb-0 lg:mt-28">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="items-center lg:flex">
            <div className="flex w-full justify-center lg:w-1/2 lg:justify-start">
              <img src="/images/404.svg" alt="404" />
            </div>
            <div className="mt-6 w-full text-center lg:mt-0 lg:w-1/2 lg:text-left">
              <h1 className="text-6xl text-sky-700 lg:text-8xl">404</h1>
              <h2 className="text-4xl text-slate-800 lg:text-7xl">
                Page Not Found
              </h2>
              <p className="mt-4 text-lg text-slate-700 lg:mt-8 lg:text-2xl">
                Couldn't find what you need? Take a moment and start again from
                the homepage.
              </p>
              <Link
                to="/"
                className="mt-5 flex items-center rounded-full bg-sky-500 px-6 py-4 font-medium uppercase text-white hover:bg-sky-600 lg:mt-10"
              >
                Go to homepage
                <Icon icon="bytesize:arrow-right" className="ml-auto" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;

import { FunctionComponent, useState } from "react";
import { Icon } from "@iconify/react";
import { classNames } from "utils";
import ParticipantList from "./ParticipantList";
import UserVideo from "./Video/UserVideo";
import useStreamStore from "./store/StreamStore";
import useMeetingStore from "./store/MeetingStore";
const XL_SCREEN = 1280;

const Sidebar: FunctionComponent = () => {
  const [show, setShow] = useState(window.innerWidth > XL_SCREEN);
  const { hasScreenTrack: isUserSharingScreen } = useStreamStore();
  const hasParticipants = useMeetingStore(
    (state) => !!state.participants?.length,
  );

  return (
    <div className="absolute right-0 top-0 z-20 h-full lg:relative">
      <button
        type="button"
        className={classNames(
          show ? "-ml-3" : "-ml-7",
          "absolute left-0 top-0 mt-4 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800",
        )}
        onClick={() => setShow(!show)}
      >
        {!show && (
          <Icon icon="ci:arrow-left-sm" className="h-5 w-5 text-white" />
        )}
        {show && (
          <Icon icon="ci:arrow-right-sm" className="h-5 w-5 text-white" />
        )}
      </button>
      <div
        className={classNames(
          show ? "w-80" : "w-0",
          "flex h-full flex-col border-l border-black bg-gray-800 transition-all duration-300 ease-in-out",
        )}
      >
        {show && (
          <>
            <ParticipantList />
            {hasParticipants && !isUserSharingScreen && (
              <div className="mt-auto p-2">
                <UserVideo className="h-[220px] w-full" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;

import { FunctionComponent, useEffect, useState } from "react";
import useMeetingStore from "../store/MeetingStore";
import useStreamStore from "../store/StreamStore";
import ParticipantItem from "./ParticipantItem";

const UserParticipant: FunctionComponent = () => {
  const [participant, setParticipant] = useState<any>(null);
  const { me } = useMeetingStore();
  const { audioEnabled, videoEnabled, isTalking } = useStreamStore();

  useEffect(() => {
    setParticipant({
      id: me?.id,
      firstName: me?.firstName,
      lastName: me?.lastName,
      image: me?.image,
      isTalking,
      audioEnabled,
      videoEnabled,
    });
  }, [me, isTalking, audioEnabled, videoEnabled]);

  return <ParticipantItem participant={participant} />;
};

export default UserParticipant;

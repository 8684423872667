import { FunctionComponent, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { classNames } from "utils";
import LoadingSpinner from "../../../LoadingSpinner";
import useMediaToggle from "../../hooks/useMediaToggle";
import useStreamStore from "../../store/StreamStore";

const ScreenShareButton: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [screenOn, setScreenOn] = useState(false);
  const { toggleScreenShare } = useMediaToggle();
  const { videoEnabled, hasScreenTrack } = useStreamStore();

  const onToggleVideoClicked = () => {
    if (loading) return;
    setLoading(true);
    toggleScreenShare().then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setScreenOn(videoEnabled && hasScreenTrack);
  }, [videoEnabled, hasScreenTrack]);

  return (
    <div
      className="flex items-center"
      aria-label={screenOn ? "Screen share on" : "Screen share off"}
      data-balloon-pos="up"
    >
      <button
        type="button"
        className={classNames(
          screenOn ? "bg-green-600" : "bg-white/20",
          "relative flex h-10 w-10 items-center justify-center rounded-full",
        )}
        onClick={() => onToggleVideoClicked()}
      >
        {loading && (
          <div className="h-7 w-6">
            <LoadingSpinner size={6} />
          </div>
        )}
        {!loading && (
          <Icon
            data-testid="screen-share-icon"
            icon="solar:screencast-2-bold"
            className="las text-2xl text-white"
          />
        )}
      </button>
    </div>
  );
};

export default ScreenShareButton;

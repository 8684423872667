import { useEffect, useRef } from "react";
import { useWebWorker } from "utils/hooks";
import useStreamStore from "../store/StreamStore";

const soundFunction = () => {
  self.onmessage = (event) => {
    const { dataArray = [], threshold = 10 } = event?.data || {};
    let sum = 0;

    for (let i = 0; i < dataArray.length; i++) {
      sum += dataArray[i];
    }

    const average = sum / dataArray.length;

    if (average > threshold) {
      self.postMessage(true);
    } else {
      self.postMessage(false);
    }
  };
};

const useSoundDetection = () => {
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const soundDetectionRef = useRef<any>(null);
  const { stream, setIsTalking } = useStreamStore();
  const { postMessage, result: isTalking } = useWebWorker(soundFunction);

  const startSoundDetection = () => {
    stopSoundDetection(); // Ensure any existing interval is cleared
    soundDetectionRef.current = setInterval(() => {
      if (!analyserRef.current || !dataArrayRef.current) return;

      analyserRef.current.getByteFrequencyData(dataArrayRef.current);

      postMessage({
        dataArray: dataArrayRef.current,
        threshold: 10,
      });
    }, 100);
  };

  const stopSoundDetection = () => {
    if (soundDetectionRef.current !== null) {
      clearInterval(soundDetectionRef.current);
      soundDetectionRef.current = null;
    }
  };

  useEffect(() => {
    setIsTalking(!!isTalking);
  }, [isTalking]);

  useEffect(() => {
    if (!stream?.getAudioTracks().length) return;

    const audioContext = new (window.AudioContext ||
      // @ts-ignore
      window.webkitAudioContext)();
    analyserRef.current = audioContext.createAnalyser();
    analyserRef.current.fftSize = 256; // Size of FFT used for analysis

    // Create a media stream source
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyserRef.current);

    // Create a data array to store the audio analysis results
    dataArrayRef.current = new Uint8Array(
      analyserRef.current.frequencyBinCount,
    );

    return () => {
      analyserRef.current = null;
      dataArrayRef.current = null;
      audioContext.close();
    };
  }, [stream]);

  return { startSoundDetection, stopSoundDetection };
};

export default useSoundDetection;

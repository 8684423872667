import { FunctionComponent, useRef, useState, useEffect } from "react";
import MediaControls from ".";
import Avatar from "../../Avatar";
import useMeetingStore from "../store/MeetingStore";
import useUserMediaStore from "../store/UserMediaStore";
import useStreamStore from "../store/StreamStore";

// const openSettings = () => {
//   let settingsUrl;
//   const userAgent = navigator.userAgent.toLowerCase();

//   if (userAgent.includes("chrome")) {
//     settingsUrl = "chrome://settings/content/camera";
//   } else if (userAgent.includes("edge")) {
//     settingsUrl = "edge://settings/content/camera";
//   } else if (userAgent.includes("firefox")) {
//     settingsUrl = "about:preferences#privacy";
//   } else {
//     alert(
//       "Please go to your browser settings to enable camera access for this site."
//     );
//     return;
//   }

//   window.open(settingsUrl, "_blank");
// };

const MediaSetup: FunctionComponent = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [mediaDenied, setMediaDenied] = useState(false);
  const [buttonMediaText, setButtonMediaText] = useState<string | null>(null);
  const [mediaWarningText, setMediaWarningText] = useState<string | null>(null);
  const { me } = useMeetingStore();
  const { stream, videoEnabled } = useStreamStore();
  const { permissions } = useUserMediaStore();

  const requestMedia = async () => {
    if (mediaDenied) {
      return alert("You need to allow media permissions to join the meeting.");
    }
  };

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, videoRef.current]);

  useEffect(() => {
    const { camera, microphone } = permissions;

    if (camera === "denied" && microphone === "denied") {
      setMediaWarningText(
        "Do you want people to see and hear you in the meeting?",
      );
      setButtonMediaText("Allow camera and microphone");
    } else if (camera === "denied") {
      setMediaWarningText("Do you want people to see you in the meeting?");
      setButtonMediaText("Allow camera");
    } else if (microphone === "denied") {
      setMediaWarningText("Do you want people to hear you in the meeting?");
      setButtonMediaText("Allow microphone");
    } else {
      setMediaWarningText(null);
      setButtonMediaText(null);
    }

    setMediaDenied(
      camera === "denied" || microphone === "denied" ? true : false,
    );
  }, [permissions]);

  return (
    <div className="relative flex h-96 w-full max-w-[600px] flex-col rounded-md bg-black shadow-xl">
      <div className="flex flex-1 flex-col items-center justify-center">
        {!videoEnabled && me && (
          <div className="relative z-20 items-center justify-center">
            <Avatar
              firstName={me?.firstName}
              lastName={me?.lastName}
              image={me?.image}
              className="h-24 w-24 rounded-full bg-white object-cover text-3xl text-black"
            />
          </div>
        )}
        {!videoEnabled && (
          <p className="text-md relative z-20 mt-4 text-center text-white">
            {mediaWarningText
              ? mediaWarningText
              : "Adjust your video and audio settings"}
          </p>
        )}
        {buttonMediaText && (
          <button
            type="button"
            onClick={() => requestMedia()}
            className="relative z-50 mt-4 rounded bg-blue-600 px-4 py-1 text-sm font-medium text-white"
          >
            {buttonMediaText}
          </button>
        )}
      </div>
      <video
        ref={videoRef}
        className="absolute z-10 h-full w-full -scale-x-100 rounded-md object-cover"
        autoPlay
        muted
        playsInline
      />
      <div className="relative z-50 mb-2 mt-auto flex w-full flex-col items-center justify-center">
        <div className="relative flex w-full items-center justify-center">
          <MediaControls />
        </div>
      </div>
    </div>
  );
};

export default MediaSetup;

import { FunctionComponent, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { classNames } from "utils";
import LoadingSpinner from "../../../LoadingSpinner";
import useMediaToggle from "../../hooks/useMediaToggle";
import useUserMediaStore from "../../store/UserMediaStore";
import useStreamStore from "../../store/StreamStore";

const MicrophoneButton: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [micDenied, setMicDenied] = useState(false);
  const { permissions } = useUserMediaStore();
  const { audioEnabled } = useStreamStore();
  const { toggleAudio } = useMediaToggle();

  const onToggleAudioClicked = () => {
    if (loading) return;
    setLoading(true);
    toggleAudio().then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setMicDenied(permissions.microphone === "denied" ? true : false);
  }, [permissions]);

  return (
    <div
      className="flex items-center"
      aria-label={audioEnabled ? "Microphone on" : "Microphone off"}
      data-balloon-pos="up"
    >
      <button
        type="button"
        className={classNames(
          micDenied ? "bg-red-600" : "bg-white/20",
          "relative flex h-10 w-10 items-center justify-center rounded-full",
        )}
        onClick={onToggleAudioClicked}
      >
        {micDenied && (
          <Icon
            data-testid="microphone-denied-icon"
            icon="ri:information-fill"
            className="absolute right-0 top-0 -mr-1 -mt-1 text-xl text-orange-400"
          />
        )}
        {loading && (
          <div className="h-7 w-6">
            <LoadingSpinner size={6} />
          </div>
        )}
        {!loading && (
          <Icon
            data-testid={
              audioEnabled ? "microphone-icon" : "microphone-icon-muted"
            }
            icon={
              audioEnabled
                ? "clarity:microphone-solid"
                : "clarity:microphone-mute-solid"
            }
            className="las text-2xl text-white"
          />
        )}
      </button>
    </div>
  );
};

export default MicrophoneButton;

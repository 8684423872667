import { type FunctionComponent } from "react";
import UserStream from "../utils/UserStream";
import Video from "./Video";
import { classNames } from "utils";

interface VideoDisplayProps {
  participant: {
    id: string;
    stream?: UserStream;
    firstName?: string;
    lastName?: string;
    image?: any;
    isTalking: boolean;
    videoEnabled: boolean;
    audioEnabled: boolean;
  };
  muted?: boolean;
  className?: string;
}

const VideoDisplay: FunctionComponent<VideoDisplayProps> = ({
  participant,
  className = "",
  muted = false,
}) => {
  return (
    <div
      className={classNames(
        participant.isTalking ? "border-blue-400" : "border-black",
        "relative z-10 flex flex-col overflow-hidden rounded-lg border-4 bg-black shadow-lg",
        className,
      )}
    >
      <Video
        muted={muted}
        firstName={participant?.firstName}
        lastName={participant?.lastName}
        image={participant?.image}
        stream={participant?.stream}
        audioEnabled={participant?.audioEnabled}
        videoEnabled={participant?.videoEnabled}
      />
    </div>
  );
};

export default VideoDisplay;

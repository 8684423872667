import { type FunctionComponent } from "react";
import { useForm, type UseFormReturn, FormProvider } from "react-hook-form";

interface FormProps {
  className?: string;
  children: any;
  mode?: "onSubmit" | "onBlur" | "onChange" | "onTouched" | "all";
  useFormMethods?: UseFormReturn;
}

const Form: FunctionComponent<FormProps> = ({
  children,
  className,
  mode = "onTouched",
  useFormMethods,
}) => {
  const methods = useFormMethods || useForm({ mode });

  return (
    <FormProvider {...methods}>
      <div data-testid="form" className={className}>
        {children}
      </div>
    </FormProvider>
  );
};

export default Form;

import { type FunctionComponent } from "react";
import useMeetingStore from "../store/MeetingStore";
import ParticipantItem from "./ParticipantItem";
import UserParticipant from "./UserParticipant";

const ParticipantList: FunctionComponent = () => {
  const { participants } = useMeetingStore();

  return (
    <div className="w-full flex-1">
      <div className="border-b border-gray-800 bg-gray-700 p-4 pl-8 font-bold text-white">
        <span>Participants</span>
        <span className="ml-2 text-xs text-gray-400">
          {/* All participants plus myself */}({participants.length + 1})
        </span>
      </div>
      <div className="h-full w-full overflow-y-scroll">
        <UserParticipant />
        {participants?.map((participant) => (
          <ParticipantItem key={participant.id} participant={participant} />
        ))}
      </div>
    </div>
  );
};

export default ParticipantList;
